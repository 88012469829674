@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.slideContainer {
  background-color: #f0f0f0;
  padding: 20px;
  z-index: 997;
}

.slideContainer h1 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin-bottom: 15px;
  text-align: center;
  font-size: 28px;
}

.slideContent {
  margin: 0 40px;
}

.cardWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
}

.agCoursesItem {
  margin: 0 15px 30px;
  overflow: hidden;
  border-radius: 28px;
  list-style-type: none !important;
}

.agCoursesItemLink {
  display: block;
  padding: 30px 20px;
  background-color: #121212;
  overflow: hidden;
  text-decoration: none;
  position: relative;

}

.agCoursesItemLink:hover {
  text-decoration: none;
  color: #FFF;
}

.agCoursesItemLink:hover .agCoursesItemBg {
  transform: scale(10);
}

.agCoursesItemTitle {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  min-height: 87px;
  margin: 0 0 25px;
  text-decoration: none;
  overflow: hidden;
  font-weight: bold;
  font-size: 30px;
  color: #FFF;
  z-index: 2;
  position: relative;
}

.agCoursesItemBg {
  height: 128px;
  width: 128px;
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 979px) {
  .agCoursesItem {
    flex-basis: calc(50% - 30px);
  }

  .agCoursesItemTitle {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .agFormatContainer {
    width: 96%;
  }
}

@media only screen and (max-width: 639px) {
  .agCoursesItem {
    flex-basis: 100%;
  }

  .agCoursesItemTitle {
    min-height: 72px;
    line-height: 1;
    font-size: 24px;
  }

  .agCoursesItemLink {
    padding: 22px 40px;
  }
}
