@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:global(*) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.homePage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    background-color: lightgray;
    padding: 0 20px;
    height: 80px;
}

.logo {
    width: 150px;
    height: auto;
    cursor: pointer;
}

.navButtons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navContainer {
    display: flex;
    align-items: center;
    gap: 30px;
}

.btnLogout {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 9px 25px;
    background-color: #90645E;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

.btnLogout:hover {
    background-color: #b28e89;
}

.btnHome {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    border: none;
    background-color: lightgray;
    transition: all 0.3s ease 0s;
    padding:31px;
    color: #90645E;
    cursor: pointer;
}

.btnHome:hover {
    color: white;
    background-color: #b28e89;
}
