@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.dashboard {
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #90645E;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0;
}

.sidebar ul li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 18px;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #90645e;
  color: #ecf0f1;
  padding: 20px;
  text-align: center;
}

.header h1 {
  margin: 0;
  font-size: 28px;
}

.content {
  padding: 20px;
  overflow-y: auto;
}

.profileContainer {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profileContainer h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.profileContainer p {
  margin-bottom: 10px;
  color: #666;
}

.profileContainer strong {
  font-weight: bold;
  color: #444;
}

.profileContainer ul {
  list-style: none;
  padding-left: 0;
}

.profileContainer ul li {
  margin-bottom: 5px;
  color: #555;
}

.profileContainer .solvedProblems {
  margin-top: 30px;
}

.profileContainer .solvedProblems p {
  margin-top: 10px;
  font-style: italic;
  color: #888;
}
