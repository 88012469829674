@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:global(*) {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.pageContainer {
  background-color: #f0f0f0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pageContainer h2 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.formContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.formContainer form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.formContainer form > div {
  display: flex;
  flex-direction: column;
}

.formContainer label {
  margin-bottom: 5px;
  font-weight: 600;
}

.formContainer input,
.formContainer textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  padding: 9px 25px;
  background-color: #90645E;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  margin-top: 10px;
}

.btn:hover {
  background-color: #b28e89;
}

.exampleContainer {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.exampleContainer div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.exampleContainer label {
  font-weight: 600;
}

.exampleInputs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.exampleInputs input {
  flex: 1;
}

.arrowBack{
  border:none;
  position:absolute;
  left:5%;
  top:5%;
  padding:10px;
}

.arrowBack:hover{
  background-color: gray;
  border-radius: 50%;
}

.cross{
  height:30px;
  border:none;
  background-color: white;
  cursor: pointer;
}

.inputBox{
  display:flex;
}