@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  position: relative;
  display:flex;
}

.problemDesc {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  width: 50%;
  padding: 20px;
  background-color: #f0f0f0;
  height:100%;
}

.codeEditor{
  width:50%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin-top:10px;
}


h1, h2 {
  margin-bottom: 10px;
}

p, ul {
  margin-bottom: 20px;
}

ul {
  padding-left: 20px;
}

.elementsList li {
  list-style-type: disc;
}

.editor {
  flex-grow: 1;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
  background-color: #000; 
  border: 1px solid #333;
}

.run {
  text-align: center;
  display:flex;
  align-items: center;
  background: linear-gradient(45deg, #ff6f61, #ff8e53);
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100px; 
  margin: 0 10px;
}

.run:hover {
  background: linear-gradient(45deg, #ff8e53, #ff6f61);
}

.outputbox {
    background-color: black;
    color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
}

.row {
  margin-bottom: 10px;
}

.selectBox {
  padding: 10px;
  background: linear-gradient(45deg, #ff6f61, #ff8e53);
  color: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
  outline: none;
  width:100px;
}

.selectBox:hover {
  background: linear-gradient(45deg, #ff8e53, #ff6f61);
}

.option {
  background: white;
  color: black;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
}

.option:hover {
  background: #ff8e53;
  color: white;
}

.runCustomInput {
  margin-bottom: 10px; 
}

.actualEditor {
  font-family: "Fira Code", "Fira Mono", monospace;
  font-size: 12px;
  color: #fff; 
  background-color: #000; 
  outline: none;
  border: none;
  height: 100vh; 
  width:100%;
}

.actualEditor:focus{
  outline:none;
  border:none;
}

.lineNumbers {
  font-family: "Fira Code", "Fira Mono", monospace;
  font-size: 12px;
  color: #999;
  margin-right: 10px;
  text-align: right;
  user-select: none;
}

.buttonAlign{
  display:flex;
  
}