@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.introdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 40px;
  background-color: #f0f0f0;
  height: 100vh; 
}

.intro {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  max-width: 600px;
  margin: 0 auto;
}

.intro header h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.intro p {
  font-size: 20px;
  margin-bottom: 20px;
}

.btn {
  font-family: "Montserrat", sans-serif;
  padding: 12px 30px;
  background-color: #90645E;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: #b28e89;
}

.devimg {
  flex: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.developer {
  max-width: 100%;
  height: auto;
  border-radius: 30px;
  object-fit: contain;
}